import axios from 'axios';

const getCourseById = (courseId) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/course/${courseId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const updateEmployeeCourseStatus = (course, status) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${course}/status/${status}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const updateEmployeeCourseSection = (course, section) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${course}/section/${section}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const updateEmployeeCoursePercentage = (course, percentage) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${course}/percentage/${percentage}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const startCourse = (course) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${course}/start`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const endCourse = (course) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${course}/end`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const getContentUrl = (fileId) => {
  return `${process.env.REACT_APP_API_ENDPOINT}/course-content/content/${fileId}?t=${localStorage.getItem('authToken')}`
}

const getCertificateBackground = (id) => {
  return `${process.env.REACT_APP_API_ENDPOINT}/course/certificate-background/${id}`;
}

const getSmallImage = (id) => {
  return `${process.env.REACT_APP_API_ENDPOINT}/course/small-image/${id}`;
}

const generateCompletionCertificate = (courseId) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${courseId}/certificate`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const getEmployeeCourses = (page, size, sort, order) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employee-course?page=${page}&size=${size}&sort=${sort}&order=${order}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const getCourseStats = () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/stats`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const getCoursePage = (page, size, sort, order) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/org/course/page?page=${page}&size=${size}&sort=${sort}&order=${order}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

export { getCourseById, getContentUrl, updateEmployeeCourseStatus, updateEmployeeCourseSection, updateEmployeeCoursePercentage, getCertificateBackground, generateCompletionCertificate, getEmployeeCourses, getCourseStats, getCoursePage, getSmallImage, startCourse, endCourse };