import { Box, Button, Rating, Snackbar, TextField, Typography } from '@mui/material';
import './feedback-form.css'
import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { createReview } from '../../services/review-service';
import Spinner from '../spinner/spinner';
import { action } from '../../lib/misc';

const FeedbackForm = (props) => {
  let { evaluationMessage, course } = props;
  let { empCourseId } = useParams();
  const [snackbar, setSnackbar] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [disableSubmit, setDisableSubmit] = useState(false)

  const formik = useFormik({
    initialValues: {
      rating: 0,
      comment: ''
    },
    onSubmit: (values) => {
      submitFeedback(values);
    }
  })

  const submitFeedback = (feedback) => {
    feedback.course = course._id;
    setSpinner(true)
    createReview(feedback).then(result => {
      setSpinner(false)
      setErrorMessage('Thank you for your feedback. The feedback was saved successfully.')
      setSnackbar(true)
      setDisableSubmit(true)
    }).catch(err => {
      setSpinner(false)
      setErrorMessage(err.response.data.message)
      setSnackbar(true)
      setDisableSubmit(true)
    })
  }

  const onClose = () => {
    navigate(`/course/${empCourseId}/success`)
  }

  return (
    <div>
      <Typography variant='h4'>{evaluationMessage}</Typography>
      <div className='p-2'></div>
      <Typography variant='p'>Thank you for going through the course. Please provide your valuable feedback. Your feedback helps us in coming up with better content and user experience.</Typography>
      <div className='p-2'></div>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
          <Typography>Rating</Typography>
          <Rating name='rating' value={formik.values.rating} onChange={formik.handleChange} readOnly={disableSubmit} />
        </Box>
        <TextField multiline disabled={disableSubmit} name='comment' value={formik.values.comment} onChange={formik.handleChange} rows={5} variant='standard' label='Comments/Suggestions' className='w-100' />
        <Box sx={{ display: 'flex', gap: '5px', marginTop: '20px' }}>
          <Button variant='contained' color='primary' type='submit' disabled={disableSubmit}>Submit</Button>
          <Button variant='contained' color='error' onClick={onClose}>Close</Button>
        </Box>
      </form>
      <Spinner show={spinner} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbar}
        autoHideDuration={3000}
        message={errorMessage}
        action={action(() => setSnackbar(false))}
        onClose={() => setSnackbar(false)}
      />
    </div>
  )
}

export default FeedbackForm