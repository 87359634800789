import './table-of-contents.css'

import React from 'react'
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const TableOfContents = (props) => {
  let { course } = props;
  let { section } = useParams();
  console.log(section);
  console.log(course);
  let currentSection = course?.sections?.findIndex(s => encodeURI(s.description) === encodeURI(section));

  return (
    <div>
      <Typography variant='h6' sx={{ textAlign: 'center' }}>{course.name}</Typography>
      <div className='p-2'></div>
      <small>
        <ol className='text-white'>
          {course?.sections?.map((section, i) => <li style={currentSection === i ? { color: 'red' } : { color: 'white' }} key={i}><strong>{section.description}</strong>{currentSection === i ? <div className='text-white pt-2 pb-3 me-3' style={{ textAlign: 'justify' }}>{section.scenario}</div> : <></>}</li>)}
          <li style={section === 'quiz' ? { color: 'red' } : { color: 'white' }}>Quiz</li>
        </ol>
      </small>
    </div>
  )
}

export default TableOfContents