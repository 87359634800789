import { Button, FormControlLabel, Radio, RadioGroup, Snackbar, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { action } from '../../lib/misc';
import { updateResponses } from '../../services/employee-service';
import Spinner from '../spinner/spinner';
import './inter-video-questions.css'

const InterVideoQuestions = (props) => {
  let { course, previous, next, beginQuiz } = props
  let { section, empCourseId } = useParams()
  let currentSection = course?.sections?.filter(s => encodeURI(s.description) === encodeURI(section))[0]
  console.log(currentSection.questions)
  let isLastSection = course.sections.filter((s, i) => encodeURI(s.description) === encodeURI(section) && i === course.sections.length - 1).length > 0
  let navigate = useNavigate()
  let [showSnackbar, setShowSnackbar] = useState(false)
  let [errorMessage, setErrorMessage] = useState('')
  let [spinner, setSpinner] = useState(false)

  const onPreviousClick = () => {
    previous()
  }

  const processResponses = (values) => {
    setSpinner(true)
    updateResponses(empCourseId, values).then(() => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage(`Responses updated successfully.`)
      if (isLastSection) {
        beginQuiz()
      }
      else {
        next()
      }
    }).catch(() => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage(`Error occured while updating responses. Please contact administrator.`)
    })
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      processResponses(values);
    }
  })

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'><h3>Questions</h3><Button variant='contained' color='primary' onClick={() => navigate('/home')}>Exit</Button></div>
      <div className='p-2'></div>
      <form onSubmit={formik.handleSubmit}>
        <div className='text-center'>
          <div>
            {currentSection?.questions?.map((q, i) => (
              <div className='text-start'>
                <h5><strong>{i + 1}. {q.questionText}</strong></h5>
                {q.questionType === 'YES-NO-PREFER-NOT' ? <RadioGroup name={`${encodeURI(currentSection.description + '-' + q.questionText)}`} onChange={formik.handleChange}>
                  <FormControlLabel value={'yes'} control={<Radio value={'Yes'} />} label='Yes' />
                  <FormControlLabel value={'no'} control={<Radio value={'No'} />} label='No' />
                  <FormControlLabel value={'preferNotToSay'} control={<Radio value={'Prefer not to say'} />} label='Prefer not to say' />
                </RadioGroup> : q.questionType === 'OPEN-ENDED' ? <TextField multiline name={`${encodeURI(currentSection.description + '-' + q.questionText)}`} value={formik.values[q._id]} onChange={formik.handleChange} rows={5} variant='standard' label='Your Response' className='w-100' /> : <div></div>}
                <div className='p-2'></div>
              </div>
            ))}
          </div>
        </div>
        <div className='p-2'></div>
        <div className='text-center'>
          {/* <Button variant='contained' color='error' onClick={() => onPreviousClick()}>Previous</Button>
          <span className='p-1'></span> */}
          {isLastSection ? <Button variant='contained' color='error' type='submit'>Start Quiz</Button> : <Button variant='contained' color='error' type='submit'>Next</Button>}
        </div>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={errorMessage}
        action={action(() => setShowSnackbar(false))}
        onClose={() => setShowSnackbar(false)}
      />
      <Spinner show={spinner} />
    </div>
  )
}

export default InterVideoQuestions