import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import { createTheme, ThemeProvider } from '@mui/material';
import NewHome from './pages/newHome/newHome';
import Section from './pages/section/Section';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='' element={<Login />} />
          {/* <Route path='landing' element={<Landing />} /> */}
          <Route path='login' element={<Login />} />
          <Route path='home/*' element={<NewHome />} />
          <Route path='course/:empCourseId/:section' element={<Section />} />
          {/* <Route path='course' element={<Course />} />
          <Route path='courses' element={<Courses />} />
          <Route path='users' element={<Users />} />
          <Route path='settings' element={<SettingsPage />} />
          <Route path='scenarios' element={<Scenarios />} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
