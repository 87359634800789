import { Rating, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Spinner from '../../components/spinner/spinner'
import { action } from '../../lib/misc'
import { getUserReviews } from '../../services/review-service'
import './reviews.css'

const Reviews = () => {
  let [showSnackbar, setShowSnackbar] = useState(false)
  let [showSpinner, setShowSpinner] = useState(false)
  let [message, setMessage] = useState('')
  let [pageData, setPageData] = useState({
    data: [],
    count: 0
  })
  let [page, setPage] = useState(0)
  let [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState('createdAt')
  const [order, setOrder] = useState('desc')

  const updateSortAndOrder = (column) => {
    let sortOrder = 'asc'
    if (sort === column) {
      if (order === 'asc') {
        sortOrder = 'desc'
      }
      else {
        sortOrder = 'asc'
      }
    }
    else {
      sortOrder = 'asc'
    }
    setOrder(sortOrder)
    setSort(column)
    fetchReviews(page, pageSize, column, sortOrder)
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
    fetchReviews(page, event.target.value, sort, order)
  }

  function handleChangePage(event, page) {
    setPage(page);
    fetchReviews(page, pageSize, sort, order)
  }

  const fetchReviews = (page, size, sort, order) => {
    setShowSpinner(true)
    getUserReviews(page, size, sort, order).then(response => {
      setShowSpinner(false)
      setPageData({
        count: response.data.data.totalDocs,
        data: response.data.data.docs
      })
    }).catch(err => {
      setShowSpinner(false)
      setShowSnackbar(true)
      setMessage(`Some error occured while trying to fetch the reviews. Please contact administrator.`)
      console.log(err);
    })
  }

  useEffect(() => {
    fetchReviews(page, pageSize, sort, order)
  }, [])

  return (
    <div>
      <div className='p-2'></div>
      <Typography variant='h5'>My Reviews</Typography>
      <div className='p-2'></div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sort === 'course' ? order : false}>
                <TableSortLabel active={sort === 'course'} direction={sort === 'course' ? order : 'asc'} onClick={() => updateSortAndOrder('course')}>
                  Course
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'comment' ? order : false}>
                <TableSortLabel active={sort === 'comment'} direction={sort === 'comment' ? order : 'asc'} onClick={() => updateSortAndOrder('comment')}>
                  Comment
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'rating' ? order : false}>
                <TableSortLabel active={sort === 'rating'} direction={sort === 'rating' ? order : 'asc'} onClick={() => updateSortAndOrder('rating')}>
                  Rating
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'createdAt' ? order : false}>
                <TableSortLabel active={sort === 'createdAt'} direction={sort === 'createdAt' ? order : 'asc'} onClick={() => updateSortAndOrder('createdAt')}>
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.data.map((review, i) => <TableRow key={i}>
              <TableCell>{review.course.name}</TableCell>
              <TableCell>{review.comment}</TableCell>
              <TableCell><Rating readOnly value={review.rating} /></TableCell>
              <TableCell>{moment(review.createdAt).toDate().toLocaleDateString()}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        count={pageData.count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <Spinner show={showSpinner} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={message}
        action={action(() => setShowSnackbar(false))}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  )
}

export default Reviews