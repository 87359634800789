import './quiz.css'
import React, { useState } from 'react'
import { useFormik } from 'formik';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { evaluateQuiz } from '../../services/employee-service'

const Quiz = (props) => {
  let { course, setEvaluationMessage } = props
  let { empCourseId } = useParams()
  let navigate = useNavigate()

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      evaluateResponses(values);
    }
  })

  const evaluateResponses = (responseObject) => {
    evaluateQuiz(empCourseId, responseObject).then(result => {
      let response = result.data.data;
      if (response.action === 1) {
        setEvaluationMessage(response.result);
        navigate(`/course/${empCourseId}/feedback`)
      }
      else {
        setEvaluationMessage(`You scored ${response.score}% in the quiz. ${response.result}`);
        navigate(`/course/${empCourseId}/failure`)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='d-flex align-items-center justify-content-between mb-3'><h3>Quiz</h3><Button variant='contained' color='primary' onClick={() => navigate('/home')}>Exit</Button></div>
        {course?.quizQuestions?.map((quizQuestion, i) => <div key={i}>
          <strong>{i + 1}. {quizQuestion.questionText}</strong>
          {quizQuestion.questionType === 'TRUE-FALSE' ? <RadioGroup name={`${quizQuestion._id}`} onChange={formik.handleChange}>
            <FormControlLabel value={0} control={<Radio />} label='True' />
            <FormControlLabel value={1} control={<Radio />} label='False' />
            <FormControlLabel value={2} control={<Radio />} label="Can't say" />
          </RadioGroup> : quizQuestion.questionType === 'SINGLE-ANSWER-MCQ' ? <RadioGroup name={`${quizQuestion._id}`} onChange={formik.handleChange}>
            {quizQuestion.options.split('\n').map((option, i) => (
              <FormControlLabel value={i} key={i} control={<Radio />} label={option} />
            ))}
          </RadioGroup> : <div></div>}
          <div className='p-3'></div>
        </div>)}
        <div className='text-center'><Button variant='contained' color='error' type='submit'>Done</Button></div>
      </form>
    </div>
  )
}

export default Quiz