import './course-card.css'
import React, { useState } from 'react'
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Rating, Typography } from '@mui/material';
import { getSmallImage } from '../../services/course-service';
import CourseDetailPopup from '../course-details-popup/course-details-popup';

const CourseCard = (props) => {
  let { course, onAssign } = props;
  let [openPopup, setOpenPopup] = useState(false);
  let colors = ['#1A5F7A', '#262A56', '#7AA874', '#B8621B', '#9A208C', '#B46060', '#19A7CE', '#EA5455', '#2C3333', '#FF0303', '#D61355']
  let randomColor = colors[Math.floor(Math.random() * colors.length)];
  let [currentCourse, setCurrentCourse] = useState(null);

  const handleClose = () => {
    setOpenPopup(false)
  }

  const onShowDetails = () => {
    setOpenPopup(true);
    setCurrentCourse(course);
  }

  return (
    <Card style={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CardHeader avatar={
          <Avatar sx={{ bgcolor: `${randomColor}` }} aria-label='course'>{course.course.name.charAt(0)}</Avatar>
        } title={course.course.code} subheader={course.course.name}>
        </CardHeader>
        <CardMedia
          component='img'
          height='194'
          image={getSmallImage(course.course.smallImage)}
          alt={course.course.name}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Rating readOnly value={course.course.rating} precision={0.5} />
          <Typography gutterBottom variant='body2' color='text.secondary'>
            {course.course.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant='contained' onClick={onShowDetails}>Details</Button>
        </CardActions>
      </Box>
      <CourseDetailPopup open={openPopup} handleClose={handleClose} course={currentCourse} randomColor={randomColor} onAssign={onAssign} />
    </Card>
  )
}

export default CourseCard