import './nav.css'
import React from 'react'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Comment, ExitToApp, Home, School } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const Nav = () => {
  const navigate = useNavigate()

  const goToLink = (link) => {
    navigate(`/${link}`)
  }

  const logout = () => {
    localStorage.clear()
    navigate('/')
  }
  return (
    <div>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => goToLink(`home`)}>
            <ListItemIcon>
              <Home color='info' />
            </ListItemIcon>
            <ListItemText primary='Home' selected />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => goToLink(`home/courses`)}>
            <ListItemIcon>
              <School color='info' />
            </ListItemIcon>
            <ListItemText primary='Courses' selected />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => goToLink(`home/reviews`)}>
            <ListItemIcon>
              <Comment color='info' />
            </ListItemIcon>
            <ListItemText primary='My Reviews' selected />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => logout()}>
            <ListItemIcon>
              <ExitToApp color='info' />
            </ListItemIcon>
            <ListItemText primary='Logout' selected />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  )
}

export default Nav