import './newHome.css'
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { AppBar, CssBaseline, Drawer, IconButton, Toolbar } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import AvatarSection from '../../components/avatar-section/avatar-section'
import Nav from '../../components/nav/nav'
import jwt_decode from "jwt-decode";
import { Route, Routes } from 'react-router-dom'
import sashaLogo from '../../assets/images/sasha.png'
import Dashboard from '../dashboard/Dashboard'
import Courses from '../courses/Courses';
import Reviews from '../reviews/reviews'
import dishaFLEXLogo from '../../images/dishaflex.png'
import { useMediaQuery } from 'react-responsive'

const drawerWidth = 240;

const NewHome = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [open, setOpen] = useState(!isMobile)
  const theme = useTheme();
  const [userInfo, setUserInfo] = useState(null);

  const handleDrawerOpen = () => {
    let newOpen = !open;
    setOpen(newOpen);
  };

  useEffect(() => {
    let decoded = jwt_decode(localStorage.getItem('authToken'));
    setUserInfo(decoded);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar color='default' position='fixed' open={open} style={{
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}>
        <Toolbar sx={{ display: 'flex' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <Menu />
          </IconButton>
          <img src={dishaFLEXLogo} style={{ height: 50 }} />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'black',
            color: 'white'
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <AvatarSection userInfo={userInfo} />
        <div className='text-center d-flex flex-column'>
          <div className='text-white text-start'>
            <Nav />
          </div>
          <div className='p-4'></div>
          <div className='text-white'>Powered by <img alt='' src={sashaLogo} style={{ width: 40 }} /></div>
        </div>
      </Drawer>
      <div open={open} style={{
        flexGrow: 1,
        overflow: 'auto',
        padding: theme.spacing(2),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        })
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
          justifyContent: 'flex-end'
        }}>
        </div>
        <Routes>
          <Route path='/' element={<Dashboard />}></Route>
          <Route path='/courses' element={<Courses />}></Route>
          <Route path='/reviews' element={<Reviews />}></Route>
        </Routes>
      </div>
    </Box>
  )
}

export default NewHome