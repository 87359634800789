import { Button, Typography } from '@mui/material';
import './failure.css'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const Failure = (props) => {
  let { evaluationMessage } = props;
  let { empCourseId } = useParams();
  const navigate = useNavigate()

  const onRetakeQuiz = () => {
    navigate(`/course/${empCourseId}/quiz`)
  }

  return (
    <div>
      <Typography variant='h4'>{evaluationMessage}</Typography>
      <div className='p-2'></div>
      <Button variant='contained' color='primary' onClick={onRetakeQuiz}>Go back to Quiz</Button>
      &nbsp;
      <Button variant='contained' color='error' onClick={() => navigate('/home')}>Exit</Button>
      <div className='p-2'></div>
    </div>
  )
}

export default Failure