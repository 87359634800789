import { Box, Button, Snackbar, Typography } from '@mui/material'
import './certificate-viewer.css'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../spinner/spinner'
import { generateCompletionCertificate } from '../../services/course-service'
import { Document, Page, pdfjs } from "react-pdf";
import { action } from '../../lib/misc'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CertificateViewer = (props) => {
  let { evaluationMessage } = props;
  let { empCourseId } = useParams()
  let navigate = useNavigate()
  let [spinner, setSpinner] = useState(false)
  let [certificate, setCertificate] = useState(null)
  let [page, setPage] = useState(0)
  let [showSnackbar, setShowSnackbar] = useState(false)
  let [errorMessage, setErrorMessage] = useState(null)

  const onDocLoadSuccess = () => {
    setPage(1)
  }

  useEffect(() => {
    setSpinner(true)
    generateCompletionCertificate(empCourseId).then(result => {
      setSpinner(false)
      setCertificate(`${process.env.REACT_APP_DOC_SERVER}/${result.data.data}`)
    }).catch(err => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage(`Some error occured while fetching certificate. Please contact administrator.`)
      console.log(err)
    })
  }, [])

  return (
    <Box>
      {props ? <Typography variant='h4'>{evaluationMessage}</Typography> : <></>}
      <div className='p-2'></div>
      <Typography variant='p'>Here is your certificate</Typography>
      <div className='p-2'></div>
      {certificate ? <Document file={certificate} renderMode='canvas' onLoadSuccess={onDocLoadSuccess}>
        <Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
      </Document> : <></>}
      <div className='p-2'></div>
      <Button variant='contained' color='error' onClick={() => navigate('/home')}>Back to home</Button>
      <Spinner show={spinner} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={errorMessage}
        action={action(() => setShowSnackbar(false))}
        onClose={() => setShowSnackbar(false)}
      />
    </Box>
  )
}

export default CertificateViewer