import { Typography } from '@mui/material';
import React from 'react'
import './course-details.css'

const CourseDetails = (props) => {
  let { course } = props;

  return (
    <div>
      <Typography variant='h6' sx={{ textAlign: 'center' }}>Course Details:</Typography>
      <div className='p-2'></div>
      <div><small className='p-3 text-light'><strong>Code: </strong>{course.code}</small></div>
      <div><small className='p-3 text-light'><strong>Duration: </strong>{course.duration} mins</small></div>
      <div><small className='p-3 text-light'><strong>Level: </strong>{course.level}</small></div>
      <div><small className='p-3 text-light'><strong>Certificate: </strong>{course.certificate?.certificate ? 'Yes' : 'No'}</small></div>
      <div><small className='p-3 text-light'><strong>Credits: </strong>{course.credits}</small></div>
      <div className='text-white p-3'>
        <div style={{ textAlign: 'justify' }}><small>{course.description}</small></div>
      </div>
    </div>
  )
}

export default CourseDetails