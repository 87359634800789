import { Button } from '@mui/material';
import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import './course-intro.css'

const CourseIntro = (props) => {
  let { course, onStartCourse } = props;
  let { empCourseId } = useParams()
  let navigate = useNavigate()
  const startCourse = () => {
    onStartCourse()
  }

  return (
    <div>
      <h1>Introduction</h1>
      <div className='p-2'></div>
      <ReactMarkdown>{course.introduction}</ReactMarkdown>
      <div className='p-2'></div>
      <div className='text-center d-flex justify-content-center gap-2'>
        <Button variant='contained' color='error' onClick={() => startCourse()}>Start</Button>
        <Button variant='contained' color='primary' onClick={() => navigate('/home')}>Cancel</Button>
      </div>
    </div>
  )
}

export default CourseIntro