import './course-video.css'
import React, { useState } from 'react'
import { getContentUrl } from '../../services/course-service';
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import InterVideoQuestions from '../inter-video-questions/inter-video-questions';
import jwtDecode from 'jwt-decode';

const CourseVideo = (props) => {
  let { course, afterVideo, onPrevious } = props;
  console.log(course);
  let navigate = useNavigate()
  let [disableNext, setDisableNext] = useState(true)
  let { section, empCourseId } = useParams()
  let [showQuestions, setShowQuestions] = useState(false)
  let currentSection = course?.sections?.filter(s => encodeURI(s.description) === encodeURI(section))[0]
  let userDetails = jwtDecode(localStorage.getItem('authToken'));
  console.log(section)
  console.log(currentSection)

  const onVideoEnd = () => {
    let completedSections = course.sections.findIndex((s, i) => encodeURI(s.description) === encodeURI(section)) + 1
    let progress = Math.round(completedSections * 100 / (course.sections.length + 1))
    let nextSection = completedSections < course.sections.length ? encodeURI(course.sections[completedSections].description) : 'quiz'
    afterVideo(nextSection, progress)
    setDisableNext(false)
  }
  const onNext = () => {
    // TODO: Either show questions or go to next section
    // Check what to be done based on the current section's properties or course's properties
    setShowQuestions(true)
  }

  const onPreviousFromQuestions = () => {
    setShowQuestions(false)
  }

  const onNextFromQuestions = () => {
    let currentSection = course.sections.findIndex(s => encodeURI(s.description) === encodeURI(section))
    if (currentSection != course.sections.length - 1) {
      setShowQuestions(false)
      setDisableNext(true)
      navigate(`/course/${empCourseId}/${encodeURI(course.sections[currentSection + 1].description)}`)
    }
    else {
      navigate(`/course/${empCourseId}/quiz`)
    }
  }

  const onBeginQuizFromQuestions = () => {
    navigate(`/course/${empCourseId}/quiz`)
  }

  return (
    <div>
      {!showQuestions ? <div>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}><h3>{currentSection?.description}</h3><Button variant='contained' color='primary' onClick={() => navigate('/home')}>Exit</Button></Box>
        <div className='p-2'></div>
        <p>{currentSection?.scenario}</p>
        <p style={{fontWeight: 'bold'}}>Approximate duration: {currentSection?.content.duration} mins</p>
        <p style={{fontWeight: 'bold', fontSize: '90%', color: '#ff0000'}}>* The "Next" button would remain disabled till the video is viewed completely.</p>
        <div className='p-2'></div>
        <div className='text-center'>
          {currentSection ? <video style={{ maxWidth: '100%' }} src={getContentUrl(currentSection?.content.content[0])} preload='auto' autoPlay onEnded={() => onVideoEnd()} controls={userDetails.isTestAccount} controlsList='nodownload' onContextMenu={(e) => e.preventDefault()}></video> : <></>}
        </div>
        <div className='p-2'></div>
        <div className='text-center'>
          {/* <Button variant='contained' color='error' onClick={() => onPrevious()} disabled={currentSection === 0}>Previous</Button>
          <span className='p-1'></span> */}
          <Button variant='contained' color='error' onClick={() => onNext()} disabled={disableNext}>Next</Button>
        </div>
      </div> : <InterVideoQuestions previous={onPreviousFromQuestions} next={onNextFromQuestions} beginQuiz={onBeginQuizFromQuestions} course={course} />}
    </div>
  )
}

export default CourseVideo