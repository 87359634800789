import axios from 'axios';

const assignCourse = (courseId) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/course/${courseId}/assign`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

export { assignCourse }