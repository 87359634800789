import React, { useEffect, useState } from 'react'
import './dashboard.css'
import { Card, Divider, IconButton, LinearProgress, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { generateCompletionCertificate, getCourseStats, getEmployeeCourses } from '../../services/course-service';
import Spinner from '../../components/spinner/spinner';
import { action } from '../../lib/misc';
import moment from 'moment'
import { FirstPage, PlayCircle, WorkspacePremium } from '@mui/icons-material';
import jwtDecode from 'jwt-decode';

const Dashboard = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState(0);
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [sort, setSort] = useState('createdAt')
  const [order, setOrder] = useState('desc')
  let [coursesAssigned, setCoursesAssigned] = useState(0)
  let [coursesCompleted, setCoursesCompleted] = useState(0)
  let [totalCourses, setTotalCourses] = useState(0)
  let [spinner, setSpinner] = useState(false)
  let [showSnackbar, setShowSnackbar] = useState(false)
  let [errorMessage, setErrorMessage] = useState(null)
  let [decodedUser, setDecodedUser] = useState(null);

  const [pageData, setPageData] = useState({
    data: [],
    count: -1
  })

  const updateSortAndOrder = (column) => {
    let sortOrder = 'asc'
    if (sort === column) {
      if (order === 'asc') {
        sortOrder = 'desc'
      }
      else {
        sortOrder = 'asc'
      }
    }
    else {
      sortOrder = 'asc'
    }
    setOrder(sortOrder)
    setSort(column)
    fetchCourses(page, pageSize, column, sortOrder)
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
    fetchCourses(page, event.target.value, sort, order)
  }

  function handleChangePage(event, page) {
    setPage(page);
    fetchCourses(page, pageSize, sort, order)
  }

  const fetchCourses = (page, size, sort, order) => {
    setSpinner(true)
    getEmployeeCourses(page, size, sort, order).then(response => {
      setSpinner(false)
      setPageData({
        count: response.data.data.totalDocs,
        data: response.data.data.docs
      })
    }).catch(err => {
      console.log(err);
      setSpinner(false)
    });
  }

  useEffect(() => {
    let decoded = jwtDecode(localStorage.getItem('authToken'));
    setDecodedUser(decoded);
    fetchCourses(page, pageSize, sort, order)
    setSpinner(true)
    getCourseStats().then(result => {
      setSpinner(false)
      setCoursesAssigned(result.data.data.assigned)
      setCoursesCompleted(result.data.data.completed)
      setTotalCourses(result.data.data.total)
      setCredits(result.data.data.credits)
    }).catch(err => {
      setSpinner(false)
      console.log(err)
    })
  }, []);

  const startCourse = (courseId, section) => {
    if (section) {
      navigate(`/course/${courseId}/${section}`);
    }
    else {
      navigate(`/course/${courseId}/intro`);
    }
  }

  const restartCouse = (courseId) => {
    navigate(`/course/${courseId}/intro`);
  }

  const downloadCertificate = (courseId) => {
    setSpinner(true)
    generateCompletionCertificate(courseId).then(result => {
      setSpinner(false)
      document.getElementById(courseId).href = `${process.env.REACT_APP_DOC_SERVER}/${result.data.data}`;
      document.getElementById(courseId).click();
    }).catch(err => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage(`Some error occured while fetching certificate. Please contact administrator.`)
      console.log(err)
    })
  }

  return (
    <div>
      <div className='p-2'></div>
      <div className='row gx-3'>
        <div className='col-sm-3 mb-3'>
          <Card className='p-4 text-white' style={{ backgroundColor: '#F96666' }}>
            <h1 className='text-center'>{credits}</h1>
            <Divider />
            <div className='p-1'></div>
            <p className='text-center'>Credit Points Earned</p>
          </Card>
        </div>
        <div className='col-sm-3 mb-3'>
          <Card className='p-4 text-white' style={{ backgroundColor: '#B46060' }}>
            <h1 className='text-center'>{totalCourses}</h1>
            <Divider />
            <div className='p-1'></div>
            <p className='text-center'>Total Courses</p>
          </Card>
        </div>
        <div className='col-sm-3 mb-3'>
          <Card className='p-4 text-white' style={{ backgroundColor: '#408E91' }}>
            <h1 className='text-center'>{coursesCompleted}</h1>
            <Divider />
            <div className='p-1'></div>
            <p className='text-center'>Courses Completed</p>
          </Card>
        </div>
        <div className='col-sm-3 mb-3'>
          <Card className='p-4 text-white' style={{ backgroundColor: '#609966' }}>
            <h1 className='text-center'>{coursesAssigned}</h1>
            <Divider />
            <div className='p-1'></div>
            <p className='text-center'>Courses Assigned</p>
          </Card>
        </div>
      </div>
      <div className='p-3'></div>
      <div>
        <h4>My Courses</h4>
        <div className='p-2'></div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={sort === 'code' ? order : false}>
                  <TableSortLabel active={sort === 'code'} direction={sort === 'code' ? order : 'asc'} onClick={() => updateSortAndOrder('code')}>
                    Code
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'name' ? order : false}>
                  <TableSortLabel active={sort === 'name'} direction={sort === 'name' ? order : 'asc'} onClick={() => updateSortAndOrder('name')}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'duration' ? order : false}>
                  <TableSortLabel active={sort === 'duration'} direction={sort === 'duration' ? order : 'asc'} onClick={() => updateSortAndOrder('duration')}>
                    Duration
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'progress' ? order : false} style={{ width: '250px' }}>
                  <TableSortLabel active={sort === 'progress'} direction={sort === 'progress' ? order : 'asc'} onClick={() => updateSortAndOrder('progress')}>
                    Progress
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'deadline' ? order : false}>
                  <TableSortLabel active={sort === 'deadline'} direction={sort === 'deadline' ? order : 'asc'} onClick={() => updateSortAndOrder('deadline')}>
                    Deadline
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'status' ? order : false}>
                  <TableSortLabel active={sort === 'status'} direction={sort === 'status' ? order : 'asc'} onClick={() => updateSortAndOrder('status')}>
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.data.map((empCourse, i) => <TableRow key={i}>
                <TableCell>{empCourse.course.code}</TableCell>
                <TableCell>{empCourse.course.name}</TableCell>
                <TableCell>{empCourse.course.duration} mins</TableCell>
                <TableCell>
                  <LinearProgress valueBuffer={empCourse.progress ? empCourse.progress : 0} value={empCourse.progress ? empCourse.progress : 0} variant='buffer' style={{ width: '100px', display: 'inline-block', marginBottom: '3px', marginRight: '5px' }} /><Typography style={{ display: 'inline-block' }}>{empCourse.progress ? empCourse.progress : 0}%</Typography>
                </TableCell>
                <TableCell>{empCourse.deadline ? moment(empCourse.deadline).toDate().toLocaleDateString() : '-'}</TableCell>
                <TableCell>{empCourse.status}</TableCell>
                <TableCell style={{ width: 180 }}>
                  {decodedUser.isTestAccount ? <Tooltip title='Start Course from the beginning'>
                    <IconButton color='primary' onClick={() => restartCouse(empCourse._id)} disabled={empCourse.status === 'Completed'}>
                      <FirstPage />
                    </IconButton>
                  </Tooltip> : <></>}
                  <Tooltip title='Start Course'>
                    <IconButton color='primary' onClick={() => startCourse(empCourse._id, empCourse.section)} disabled={empCourse.status === 'Completed'}>
                      <PlayCircle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Certificate'>
                    <IconButton color='error' disabled={empCourse.status != 'Completed'} onClick={() => downloadCertificate(empCourse._id)}>
                      <WorkspacePremium />
                    </IconButton>
                  </Tooltip>
                  <a id={`${empCourse._id}`} style={{ display: 'none' }} href='#' target='_blank'></a>
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
          <div className='p-2'></div>
          <TablePagination
            component="div"
            count={pageData.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </TableContainer>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={errorMessage}
        action={action(() => setShowSnackbar(false))}
        onClose={() => setShowSnackbar(false)}
      />
      <Spinner show={spinner} />
    </div>
  )
}

export default Dashboard