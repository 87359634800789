import axios from 'axios';

const authenticate = (userDetails) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/employee/auth`, userDetails);
  }
  catch(err) {
    console.log(err);
  }
}

const getCourses = () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employee/courses`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch(err) {
    console.log(err);
  }
}

const getEmployeeCourseById = (id) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch(err) {
    console.log(err);
  }
}

const evaluateQuiz = (id, responses) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/evaluate/${id}`, responses, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch(err) {
    console.log(err);
  }
}

const updateResponses = (id, responses) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/employee-course/${id}/responses`, responses, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch(err) {
    console.log(err);
  }
}

export { authenticate, getCourses, evaluateQuiz, getEmployeeCourseById, updateResponses };