import './avatar-section.css'
import React from 'react'
import Initials from '../initials/initials'
import { getLogoUrl } from '../../services/org-service'

const AvatarSection = (props) => {
  let { userInfo } = props

  return (
    <div>
      <div className='p-2'></div>
      <div className='d-flex justify-content-center'>
        <img src={getLogoUrl()} style={{height: '40px'}}/>
      </div>
      <div className='p-2'></div>
      <div className='p-2'></div>
      <Initials name={userInfo?.name} />
      <div className='p-2'></div>
      <div className='text-center'><h5 className='text-white'>{userInfo?.name}</h5></div>
      <div className='text-center text-white'><small>{userInfo?.designation}</small></div>
      <div className='p-2'></div>
    </div>
  )
}

export default AvatarSection