import axios from 'axios';

const getOrgReviews = () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/review/org`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const getUserReviews = (page, size, sort, order) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/review/user?page=${page}&size=${size}&sort=${sort}&order=${order}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

const createReview = (course, rating, comment) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/review`, {
      course,
      rating,
      comment
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch (err) {
    console.log(err)
  }
}

export { getOrgReviews, getUserReviews, createReview }