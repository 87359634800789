import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Dialog, Rating, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import './course-details-popup.css'
import React from 'react'
import { Alarm, DoneAll, Quiz, Timeline } from '@mui/icons-material';
import { getSmallImage } from '../../services/course-service';

const CourseDetailPopup = (props) => {
  const { open, handleClose, course, randomColor, onAssign } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}>
      {course ? <Card style={{ height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <CardHeader avatar={
            <Avatar sx={{ bgcolor: `${randomColor}` }} aria-label='course'>{course.course.name.charAt(0)}</Avatar>
          } title={course.course.code} subheader={course.course.name}>
          </CardHeader>
          <CardMedia
            component='img'
            height='194'
            image={getSmallImage(course.course.smallImage)}
            alt={course.course.name}
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Rating readOnly value={course.course.rating} />
            <Typography gutterBottom variant='body2' color='text.secondary'>
              {course.course.description}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px 0px 0px' }}>
              <Tooltip title='Credits'>
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <DoneAll color='disabled' />
                  <Typography variant='body2' sx={{ cursor: 'default' }}>{course.course.credits}</Typography>
                </Box>
              </Tooltip>
              <Tooltip title='Level'>
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <Timeline color='disabled' />
                  <Typography variant='body2' sx={{ cursor: 'default' }}>{course.course.level}</Typography>
                </Box>
              </Tooltip>
              <Tooltip title='Duration'>
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <Alarm color='disabled' />
                  <Typography variant='body2' sx={{ cursor: 'default' }}>30mins</Typography>
                </Box>
              </Tooltip>
              <Tooltip title='Quiz'>
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <Quiz color='disabled' />
                  <Typography variant='body2' sx={{ cursor: 'default' }}>{course.course.hasQuiz ? 'Yes' : 'No'}</Typography>
                </Box>
              </Tooltip>
            </Box>
          </CardContent>
          <CardActions>
            <Box sx={{ display: 'flex', gap: '5px' }}>
              <Button variant='contained' onClick={onAssign}>Assign Myself</Button>
              <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
            </Box>
          </CardActions>
        </Box>
      </Card> : <></>}
    </Dialog>
  )
}

export default CourseDetailPopup