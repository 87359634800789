import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './section.css'
import { startCourse, updateEmployeeCoursePercentage, updateEmployeeCourseSection, updateEmployeeCourseStatus } from '../../services/course-service';
import CourseIntro from '../../components/course-intro/course-intro';
import TableOfContents from '../../components/table-of-contents/table-of-contents';
import CourseDetails from '../../components/course-details/course-details';
import Quiz from '../../components/quiz/quiz';
import CourseVideo from '../../components/course-video/course-video';
import CertificateViewer from '../../components/certificate-viewer/certificate-viewer';
import jwt_decode from "jwt-decode";
import AvatarSection from '../../components/avatar-section/avatar-section';
import { getEmployeeCourseById } from '../../services/employee-service';
import { Menu } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import FeedbackForm from '../../components/feedback-form/feedback-form';
import Failure from '../../components/failure/failure';

const drawerWidth = 240;

const Section = () => {
  const [open, setOpen] = useState(true)
  const theme = useTheme();
  const [course, setCourse] = useState({});
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  let [showSnackbar, setShowSnackbar] = useState(false)
  let [errorMessage, setErrorMessage] = useState('')
  let { empCourseId, section } = useParams();
  let [spinner, setSpinner] = useState(false)
  let [userInfo, setUserInfo] = useState(null)
  let [evaluationMessage, setEvaluationMessage] = useState('')

  const handleDrawerOpen = () => {
    let newOpen = !open;
    setOpen(newOpen);
  };

  useEffect(() => {
    let decoded = jwt_decode(localStorage.getItem('authToken'));
    setUserInfo(decoded);
    setSpinner(true)
    getEmployeeCourseById(empCourseId).then(response => {
      setSpinner(false)
      if (response.data.data && response.data.data.length > 0) {
        setCourse(response.data.data[0].course);
      }
      else {
        setShowSnackbar(true)
        setErrorMessage('Course not found. Please contact administrator.')
      }
    }).catch(() => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage('Error occured while loading course. Please contact administrator.')
    });
  }, [])

  const updateProgress = async(sectionName, percentage, status) => {
    try {
      setSpinner(true)
      await updateEmployeeCourseSection(empCourseId, encodeURI(sectionName));
      await updateEmployeeCoursePercentage(empCourseId, percentage);
      await updateEmployeeCourseStatus(empCourseId, status);
    }
    catch(err) {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage('Error occured while updating course section, percentage and status. Please contact administrator.')
    }
    // updateEmployeeCourseSection(empCourseId, encodeURI(sectionName)).catch(err => {
    //   setSpinner(false)
    //   setShowSnackbar(true)
    //   setErrorMessage('Error occured while updating course section. Please contact administrator.')
    // }).then(() => {
    //   setSpinner(false)
    // })
    // updateEmployeeCoursePercentage(empCourseId, percentage).catch(err => {
    //   setSpinner(false)
    //   setShowSnackbar(true)
    //   setErrorMessage('Error occured while updating course percentage. Please contact administrator.')
    // }).then(() => {
    //   setSpinner(false)
    // })
    // updateEmployeeCourseStatus(empCourseId, status).catch(err => {
    //   setSpinner(false)
    //   setShowSnackbar(true)
    //   setErrorMessage('Error occured while updating course status. Please contact administrator.')
    // }).then(() => {
    //   setSpinner(false)
    // })
  }

  const onPrevious = () => {
    let currentSection = course.sections.findIndex(s => encodeURI(s.description) === section);
    if (currentSection === 0) {
      navigate(`/course/${empCourseId}/intro`);
    }
    else {
      let previousSection = encodeURI(course.sections[currentSection - 1].description);
      navigate(`/course/${empCourseId}/${previousSection}`);
    }
  }

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  const afterVideo = (sectionName, percentage) => {
    updateProgress(sectionName, percentage, 'Started')
  }

  const onStartCourse = () => {
    setSpinner(true);
    startCourse(empCourseId).then(async(result) => {
      await updateProgress(course.sections[0].description, 0, 'Started')
      navigate(`/course/${empCourseId}/${encodeURI(course.sections[0].description)}`)
      setSpinner(false);
      setShowSnackbar(true);
      setErrorMessage(`Course started!`);
    }).catch(err => {
      setSpinner(false);
      setShowSnackbar(true);
      setErrorMessage(`Course could not be started. Please contact support.`);
    });
  }

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' open={open} style={{
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2 }}
              >
                <Menu />
              </IconButton>
              <Typography variant='h6'>{`${course.code} - ${course.name}`}</Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: 'black',
              color: 'white'
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <AvatarSection userInfo={userInfo} />
          <Divider />
          <div className='p-2'></div>
          {section === 'intro' ? <CourseDetails course={course} /> : <TableOfContents course={course} />}
          <div className='p-4'></div>
        </Drawer>
        <div open={open} style={{
          flexGrow: 1,
          padding: theme.spacing(3),
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: `-${drawerWidth}px`,
          ...(open && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          })
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end'
          }}>
          </div>
          <div className='content-area p-4'>
            {section === 'intro' ? <CourseIntro onStartCourse={onStartCourse} course={course} /> : section === 'quiz' ? <Quiz course={course} setEvaluationMessage={setEvaluationMessage} /> : section === 'success' ? <CertificateViewer course={course} evaluationMessage={evaluationMessage} /> : section === 'failure' ? <Failure course={course} evaluationMessage={evaluationMessage} /> : section === 'feedback' ? <FeedbackForm course={course} evaluationMessage={evaluationMessage} /> : <CourseVideo onPrevious={onPrevious} afterVideo={afterVideo} course={course} />}
          </div>
        </div>
      </Box>
    </div>
  )
}

export default Section