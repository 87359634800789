import React, { useEffect, useState } from 'react'
import './courses.css'
import { Pagination, Snackbar } from '@mui/material'
import Spinner from '../../components/spinner/spinner';
import { action } from '../../lib/misc';
import { getCoursePage } from '../../services/course-service'
import CourseCard from '../../components/course-card/course-card'
import { assignCourse } from '../../services/assignment-service'
import { getOrgReviews } from '../../services/review-service'

const Home = () => {
  let [spinner, setSpinner] = useState(false)
  let [showSnackbar, setShowSnackbar] = useState(false)
  let [errorMessage, setErrorMessage] = useState(null)
  let [page, setPage] = useState(1)
  let [sort, setSort] = useState('createdAt')
  let [order, setOrder] = useState('desc')
  let [rows, setRows] = useState([])
  let [total, setTotal] = useState(0)
  let [rowSize, setRowSize] = useState(3)
  let [pageSize, setPageSize] = useState(12)

  const handleChange = (event, value) => {
    console.log(value)
    setPage(value)
    fetchPage(value)
  }

  const fetchPage = (page) => {
    setSpinner(true)
    getCoursePage(page, pageSize, sort, order).then(result => {
      setSpinner(false)
      let rows = []
      let row = null
      result.data.data.docs.forEach((course, i) => {
        if (i % rowSize === 0) {
          row = []
          rows.push(row)
        }
        row.push(course)
      })
      setRows(rows)
      setTotal(result.data.data.totalDocs)
    })
  }

  const onAssign = (courseId) => {
    setSpinner(true)
    assignCourse(courseId).then(() => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage(`You successfully assigned the course to yourself. Please go to the home screen to start the course.`);
    }).catch(err => {
      setSpinner(false)
      setShowSnackbar(true)
      setErrorMessage(err.response.data.message)
    })
  }

  useEffect(() => {
    fetchPage(1)

  }, [])

  return (
    <div>
      <div className='p-2'></div>
      <h4>Available Courses</h4>
      <div className='pb-3'></div>
      {rows.map((row, i) => <div key={i} className='row w-100' style={{minWidth: '350px'}}>
        {row.map((course, j) => <div key={j} className={`col-lg-${12 / rowSize} col-md-${12/(rowSize - 1)}} col-sm-${12/(rowSize - 2)}} mb-4`}><CourseCard course={course} onAssign={() => onAssign(course.course._id)} /></div>)}
      </div>)}
      <div className='p-3'>
        <Pagination count={Math.ceil(total / pageSize)} page={page} onChange={handleChange} />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={errorMessage}
        action={action(() => setShowSnackbar(false))}
        onClose={() => setShowSnackbar(false)}
      />
      <Spinner show={spinner} />
    </div>
  )
}

export default Home